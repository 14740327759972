#home_slider {

	.bg {
		display : block;
		height  : calc(100vh - 92px);
		position: relative;

		@include breakpoint(small down) {
			min-height: calc(100vh - 92px);
			height    : auto;
		}

		article {
			position   : absolute;
			left       : 0;
			right      : 0;
			bottom     : 78px;
			width      : 640px;
			margin     : auto;
			text-align : center;
			line-height: 18px;

			@include breakpoint(medium down) {
				width: calc(100% - 30px);
			}

			@include breakpoint(small down) {
				width  : calc(100% - 20px);
				padding: 15px 35px;
				bottom : 35px;
			}

			>a {
				color        : $white;
				display      : block;
				padding      : 15px 58px;
				background   : rgba(1, 41, 85, 0.2);
				border-radius: 5px;

				&:hover {
					background: rgba(1, 41, 85, 0.4);
				}

				.reference,
				.taille {
					font-size  : 14px;
					font-weight: 500;

					@include breakpoint(small down) {
						font-size: 12px;
					}
				}

				.reference {
					font-weight: 600;
				}

				h2 {
					font-size  : 16px;
					font-weight: 700;
					margin     : 8px 0;

					@include breakpoint(small down) {
						font-size: 14px;
					}
				}
			}
		}
	}

	.flex-direction-nav {
		position: absolute;
		left    : 0;
		right   : 0;
		bottom  : 112px;
		width   : 640px;
		margin  : auto;
		@include clearfix();

		@include breakpoint(medium down) {
			width: calc(100% - 30px);
		}

		@include breakpoint(small down) {
			width : calc(100% - 20px);
			bottom: 69px;
		}

		a {
			display        : block;
			position       : relative;
			opacity        : 1;
			font-size      : 0;
			width          : 18px;
			height         : 31px;
			margin         : 0;
			background     : url(../img/arrow_slider.png) center no-repeat;
			background-size: 100%;

			&:before {
				content: none;
			}

			&.flex-next {
				float: right;
				right: 30px;

				@include breakpoint(small down) {
					right: 10px;
				}
			}

			&.flex-prev {
				float: left;
				left : 30px;
				@include rotate(180deg);

				@include breakpoint(small down) {
					left: 10px;
				}
			}
		}
	}

	#home_search {
		width   : 1117px;
		position: absolute;
		left    : 0;
		right   : 0;
		margin  : auto;
		bottom  : 200px;
		z-index : 10;
		@include clearfix();

		@include breakpoint(large down) {
			width: calc(100% - 30px);
		}

		@include breakpoint(small down) {
			width: calc(100% - 20px);
		}
	}
}



#home_texte {
	padding-top   : 92px;
	padding-bottom: 75px;
	text-align    : center;
	color         : $white;
	position      : relative;


	>figure {
		position: absolute;
		width   : 100%;
		height  : 100%;
		top     : 0;
		left    : 0;

		&:after {
			content   : "";
			position  : absolute;
			top       : 0;
			left      : 0;
			width     : 100%;
			height    : 100%;
			z-index   : 1;
			background: rgba(1, 41, 85, 0.7);
		}


		img {
			display   : block;
			width     : 100%;
			height    : 100%;
			object-fit: cover;
		}
	}

	@include breakpoint(medium down) {
		padding: 50px 0;
	}

	@include breakpoint(small down) {
		padding: 30px 0;
	}

	.container {
		position: relative;
		z-index : 2;

		h1 {
			font-size     : 24px;
			font-weight   : 700;
			text-transform: uppercase;
			line-height   : 36px;
			margin-bottom : 20px;

			@include breakpoint(medium down) {
				font-size: 20px;
			}

			@include breakpoint(small down) {
				font-size  : 16px;
				line-height: 24px;
			}
		}

		h2 {
			font-size     : 18px;
			font-weight   : 700;
			text-transform: uppercase;
			line-height   : 36px;
			margin-bottom : 15px;

			@include breakpoint(medium down) {
				font-size: 16px;
			}

			@include breakpoint(small down) {
				font-size  : 14px;
				line-height: 24px;
			}
		}

		h3 {
			font-size    : 18px;
			line-height  : 36px;
			margin-bottom: 20px;

			@include breakpoint(medium down) {
				font-size: 14px;
			}

			@include breakpoint(small down) {
				font-size  : 12px;
				line-height: 20px;
			}
		}

		p {
			font-size  : 16px;
			line-height: 30px;

			@include breakpoint(xlarge up) {
				padding: 0 170px;
			}

			@include breakpoint(medium down) {
				font-size: 14px;
			}

			@include breakpoint(small down) {
				font-size  : 10px;
				line-height: 20px;
			}
		}
	}
}

#home_appartements {
	padding-top: 55px;
	@include clearfix();

	@include breakpoint(small down) {
		padding-top: 30px;
	}

	h2 {
		@include homeTitle($blue);
		margin-bottom: 55px;

		@include breakpoint(small down) {
			margin-bottom: 30px;
		}
	}

	.social_menu {
		@include grid-column(4, 0);
		float      : right;
		line-height: 312px;
		height     : 312px;
		background : $darkGreyBg;


		@include breakpoint(medium down) {
			width      : 100%;
			height     : 150px;
			line-height: 150px;
		}

		@include breakpoint(small down) {
			height     : 90px;
			line-height: 90px;
		}
	}
}

#home_louer,
#home_services {

	article {
		padding: 60px 0;

		@include breakpoint(small down) {
			padding: 30px 0;
		}

		h2 {
			@include homeTitle($blue);
			margin-bottom: 55px;

			@include breakpoint(small down) {
				margin-bottom: 30px;
			}
		}

		.texte {
			margin-bottom: 45px;

			@include breakpoint(small down) {
				margin-bottom: 30px;
			}

			p {
				color      : $greyText;
				font-size  : 16px;
				line-height: 25px;
				font-weight: 500;

				&:not(:last-child) {
					margin-bottom: 15px;
				}

				@include breakpoint(small down) {
					font-size  : 12px;
					line-height: 25px;
				}
			}
		}
	}
}

#home_biens {
	padding   : 55px 0;
	background: $lightGreyBg;

	h2 {
		@include homeTitle($blue);
		margin-bottom: 55px;
	}
}

#home_nos_quartiers,
#home_nos_arrondissements,
#recherche_nos_arrondissements,
#recherche_liens_bottom {

	article {
		padding: 45px 0;

		@include breakpoint(small down) {
			padding: 30px 0;
		}

		h2 {
			@include homeTitle($blue);
			margin-bottom: 45px;

			@include breakpoint(small down) {
				margin-bottom: 30px;
			}
		}
	}
}

#home_louer,
#home_services,
#home_nos_quartiers,
#home_nos_arrondissements,
#recherche_nos_arrondissements,
#recherche_liens_bottom {
	text-align   : center;
	padding-right: 20px;
	padding-left : 20px;

	@include breakpoint(small down) {
		padding-left : 15px;
		padding-right: 15px;
	}

	&.grey {
		padding-top: 20px;

		.background {
			background: $otherGreyBg;
			padding   : 0 15px;

			@include breakpoint(large up) {

				article .texte {
					padding: 0 50px;
				}
			}
		}
	}

	&.white {
		padding-bottom: 20px;
		background    : $otherGreyBg;

		.background {
			background: $white;
			padding   : 0 15px;

			@include breakpoint(large up) {

				article .texte {
					padding: 0 205px;
				}
			}
		}
	}
}