.liste_appartements_home {
	@include row();
	margin   : 0;
	font-size: 0;

	&:after {
		content: none;
	}

	li {
		@include grid-column(8, 0);

		@include breakpoint(medium down) {
			width: 100%;
		}

		&:last-child:not(:first-child) {
			float: left;
		}

		@include breakpoint(large up) {

			&:nth-child(3n+2) {
				@include grid-column(4, 0);
				float: right;

				@include breakpoint(medium down) {
					width: 100%;
					float: left;
				}

				article figure {

					&:before,
					&:after {
						bottom: 0;
						left  : 0;
						right : 0;
					}
				}
			}

			&:not(:nth-child(3n+2)) {
				@include clearfix();

				article {

					figure,
					.contenu {
						float: left;
						width: 50%;
					}

					figure {

						&:before,
						&:after {
							bottom: 0;
							top   : 0;
							right : -15px;
							@include rotate(-90deg);
						}

						&:after {
							background-image: url(../img/triangle_blue_gradient.png) !important;
						}
					}
				}
			}

			&:nth-child(3n+3) {
				margin-top: -312px;
				article figure {
					float: right;

					&:before,
					&:after {
						bottom: 0;
						left  : -15px;
						top   : 0;
						right : inherit;
						@include rotate(90deg);
					}
				}
			}
		}

		@include breakpoint(medium only) {
			@include clearfix();

			article {

				figure,
				.contenu {
					float: left;
					width: 50%;
				}

				figure {

					&:before,
					&:after {
						bottom: 0;
						top   : 0;
						right : -15px;
						@include rotate(-90deg);
					}

					&:after {
						background-image: url(../img/triangle_blue_gradient.png) !important;
					}
				}
			}

			&:nth-child(2n+2) article figure {
				float: right;

				&:before,
				&:after {
					bottom: 0;
					left  : -15px;
					top   : 0;
					right : inherit;
					@include rotate(90deg);
				}
			}
		}

		.bg {
			display: block;
		}

		article {

			figure {
				overflow: hidden;
				height  : 312px;
				position: relative;

				img {
					min-width: 100%;
					height   : auto;
				}

				@include breakpoint(small down) {
					height  : 150px;
					position: relative;

					img {
						position: absolute;
						top     : -999px;
						bottom  : -999px;
						width   : 100%;
						height  : auto;
						margin  : auto;
						z-index : -1;
					}
				}

				&:before,
				&:after {
					content : "";
					position: absolute;
					width   : 50px;
					margin  : auto;
					height  : 21px;
					@include transition(0.3s);

					@include breakpoint(small down) {
						bottom: 0;
						left  : 0;
						right : 0;
						margin: auto;
					}
				}

				&:before {
					background     : url(../img/triangle_grey.png) center no-repeat;
					background-size: 100%;
				}

				&:after {
					background     : url(../img/triangle_blue.png) center no-repeat;
					background-size: 100%;
					opacity        : 0;
				}
			}

			.contenu {
				background : $greyBg;
				height     : 312px;
				padding    : 0 30px;
				padding-top: 70px;
				position   : relative;
				z-index    : 1;

				@include breakpoint(1300px down) {
					padding-top: 40px;
				}

				@include breakpoint(large down) {
					padding    : 0 15px;
					padding-top: 40px;
				}

				@include breakpoint(small down) {
					height        : auto;
					padding-bottom: 15px;
				}

				&:before {
					content : "";
					position: absolute;
					z-index : -1;
					top     : 0;
					bottom  : 0;
					left    : 0;
					right   : 0;
					@include blueGradient();
					@include transition(0.3s);
					opacity: 0;
				}

				i {
					position: absolute;
					top     : 35px;
					left    : 30px;
					@include transition(0.3s);

					@include breakpoint(1300px down) {
						top: 15px;
					}

					@include breakpoint(large down) {
						left: 15px;
					}

					img {
						width : 59px;
						height: auto;
					}

					&.hover {
						opacity: 0;
					}
				}

				h3 {
					padding-left  : 80px;
					text-transform: uppercase;
					font-size     : 24px;
					font-weight   : 700;
					line-height   : 25px;
					margin-bottom : 30px;
					color         : $blue;
					@include transition(0.3s);

					@include breakpoint(large down) {
						font-size: 18px;
					}
				}

				p {
					color      : $greyText;
					font-size  : 16px;
					line-height: 30px;
					@include transition(0.3s);

					@include breakpoint(large down) {
						font-size: 14px;
					}

					@include breakpoint(small down) {
						font-size  : 12px;
						line-height: 25px;
					}
				}
			}

			&:hover {

				figure {

					&:before {
						opacity: 0;
					}

					&:after {
						opacity: 1;
					}
				}

				.contenu {

					&:before {
						opacity: 1;
					}

					i {

						&.hover {
							opacity: 1;
						}

						&:not(.hover) {
							opacity: 0;
						}
					}

					h3,
					p {
						color: $white;
					}
				}
			}
		}
	}
}