#home_luxe,
#section_conciergerie {
	padding-top   : 70px;
	padding-bottom: 80px;
	position      : relative;
	text-align    : center;

	position: relative;

	>figure {
		position: absolute;
		width   : 100%;
		height  : 100%;
		top     : 0;
		left    : 0;

		&:after {
			content   : "";
			position  : absolute;
			top       : 0;
			left      : 0;
			width     : 100%;
			height    : 100%;
			z-index   : 1;
			background: rgba(1, 41, 85, 0.7);
		}


		img {
			display   : block;
			width     : 100%;
			height    : 100%;
			object-fit: cover;
		}
	}

	@include breakpoint(medium down) {
		padding: 50px 0;
	}

	@include breakpoint(small down) {
		padding: 30px 0;
	}

	&:after {
		content   : "";
		position  : absolute;
		top       : 0;
		bottom    : 0;
		left      : 0;
		right     : 0;
		z-index   : 1;
		background: rgba(17, 17, 17, 0.8);
	}

	.container {
		position: relative;
		z-index : 2;

		h2 {
			@include homeTitle($gold);
			margin-bottom: 60px;

			@include breakpoint(medium down) {
				margin-bottom: 50px;
			}

			@include breakpoint(small down) {
				margin-bottom: 30px;
			}
		}

		.texte {
			margin-bottom: 70px;

			@include breakpoint(xlarge up) {
				padding: 0 75px;
			}

			@include breakpoint(small down) {
				margin-bottom: 30px;
			}

			p {
				color      : $white;
				font-size  : 16px;
				line-height: 30px;
				font-weight: 500;

				&:not(:last-child) {
					margin-bottom: 15px;
				}

				@include breakpoint(small down) {
					font-size  : 12px;
					line-height: 25px;
				}
			}
		}

		.boutons {
			padding-top: 60px;

			a {

				&:not(:last-child) {
					margin-right: 35px;

					@include breakpoint(small down) {
						margin-right : 0;
						margin-bottom: 20px;
					}
				}

				&.trouver {
					@include goldButton();

					@include breakpoint(small down) {
						display: block;
					}
				}

				&.contact {
					@include goldBorderButton();

					@include breakpoint(small down) {
						display: block;
					}
				}
			}
		}
	}
}