// Import setup
@import 'foundation';

@import "setup/normalize.scss";
@import "setup/variables.scss";
@import "setup/mixins.scss";
@import "setup/mixins/clearfix.scss";
@import "setup/mixins/triangle.scss";
@import "setup/grid.scss";
@import "setup/cssowl.scss";
@import "setup/sprites/global.scss";
@import "setup/sprites/picto.scss";
@import "setup/sprites/share.scss";

// Import componants 
@import "componants/animate.scss";
@import "componants/dialog.scss";
@import "componants/flexslider.scss";
@import "componants/font-awesome/font-awesome.scss";
@import "elements/titles.scss";
@import "elements/button.scss";

@import "elements/gradient.scss";





/* BLOCKS */

// @import "blocks/autre_recherche.scss";
// @import "blocks/node_banner.scss";
 @import "blocks/block_black_rondsliste.scss";

/* LISTS */
@import "lists/liste_appartements_home.scss";
// @import "lists/liste_appartements_page.scss";
@import "lists/listes_ronds.scss";
@import "lists/liste_biens.scss";
// @import "lists/liste_equipements.scss";
// @import "lists/liste_pieces.scss";
// @import "lists/liste_prix_bien.scss";
// @import "lists/liste_calendriers.scss";
// @import "lists/liste_faq.scss";
// @import "lists/liste_rencontrer.scss";
@import "lists/liste_liens_home.scss";
// @import "lists/liste_selection.scss";
// @import "lists/liste_services.scss";


@import "home.scss";