@mixin goldGradient() {
	background: $lightGold; /* Old browsers */
	background: -moz-linear-gradient(top, $lightGold 1%, $darkGold 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, $lightGold 1%,$darkGold 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, $lightGold 1%,$darkGold 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$lightGold', endColorstr='$darkGold',GradientType=0 ); /* IE6-9 */
}

@mixin blueGradient() {
	background: $lightGold; /* Old browsers */
	background: -moz-linear-gradient(top, $darkBlue 1%, $lightBlue 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, $darkBlue 1%,$lightBlue 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, $darkBlue 1%,$lightBlue 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$darkBlue', endColorstr='$lightBlue',GradientType=0 ); /* IE6-9 */
}