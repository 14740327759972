.liste_liens_home {
	columns: 4;

	@include breakpoint(large down) {
		columns: 3;
	}

	@include breakpoint(medium down) {
		columns: 2;
	}

	@include breakpoint(small down) {
		columns: 1;
	}

	li {
		margin-bottom: 10px;
		padding: 0 15px;

		a {
			color: $greyText;
			font-style: 16px;
			line-height: 20px;

			&:hover {
				color: $blue;
			}
		}
	}
}