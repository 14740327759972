/* COULEURS */
$black: #000000;
$lightBlack: #494949;
$lessLightBlack: #2e2e2e;
$white: #ffffff;

$greyBorder: #efefef;
$lightGreyBorder: #e4e7e7;
$otherGreyBorder: #d8d8d8;
$greyWebformBorder: #bdbdbd;
$yetAnotherGreyBorder: #cbcbcb;
$ahmagadAnotherGreyBorder: #e0e0e0;
$stopWithTheDifferentGreysAlready: #cdcdcd;
$greyBg: #f7f7f7;
$darkGreyBg: #e3e3e3;
$lightGreyBg: #f2f2f2;
$otherGreyBg: #e5e5e5;
$greyText: #a4a4a4;
$greyRTE: #989898;
$lightGreyText: #bbbbbb;
$lighterGreyText: #a0a0a0;
$otherGreyText: #9d9e9e;
$wowAnotherGreyText: #757575;
$guessWhatItsAnotherGreyText: #939393;
$greyTextShadow: #616161;
$greyCheckbox: #929292;
$darkGrey: #454545;
$whenWillThisStahpOhPleaseMakeItStop: #f5f5f5;
$imTooOldForThisShitIGiveUpHastaLaVista: #9f9f9f;
$didYouReallyNeedThisDifferentGrey: #bfbfbf;
$iGiveUp: #6c6c6c;
$greyBlue: #cdd3da;

$blue: #012955;
$darkBlue: #012955;
$lightBlue: #1f5189;
$otherBlue: #325984;
$blueCalendar: #073057;

$gold: #a88a57;
$darkGold: #7d6233;
$lightGold: #ac8e5a;

$green: #4ad585;
$red: #e20025;

$mainColor: $black; 
$secondColor: $black;

$bodyBG : $white;


/* COULEURS RESEAUX SOCIAUX */
$colorFacebook : 	#3b5998;
$colorTwitter : 	#00aced;
$colorInstagram : 	#125688;
$colorYoutube : 	#bb0000;
$colorGoogle : 		#dd4b39;
$colorVimeo : 		#4bf;
$colorLinkedin : 	#0077b5;
$colorYoutube : 	#dd2c28;
$colorWeibo : 		#fd8149;
$colorPinterest: 	#cb2026;


/* FONT WEIGHT */
$fwThin: 		100;
$fwExtraLight : 200; 
$fwLight : 		300; 
$fwNormal : 	400;               
$fwMedium : 	500;
$fwSemiBold : 	600;
$fwBold : 		700;  
$fwExtraBold : 	800; 
$fwUltraBold : 	900;


/* FONT FAMILY */
$ffT: 'Montserrat', sans-serif;
$ffP: 'Montserrat', sans-serif;
$ffIcon : 'fontawesome';


/* Media queries breakpoints  */
$screen-xs:         479px;
$screen-xs-min:     $screen-xs;
$screen-phone:      $screen-xs-min;

$screen-sm:         767px;
$screen-sm-min:     $screen-sm;
$screen-tablet:     $screen-sm-min;

$screen-md:         991px;
$screen-md-min:     $screen-md;
$screen-desktop:    $screen-md-min;

$screen-lg:         1199px;
$screen-lg-min:     $screen-lg;
$screen-lg-desktop: $screen-lg-min;