@mixin goldButton() {
	display: inline-block;
	vertical-align: middle;
	font-size: 16px;
	font-weight: 700;
	line-height: 30px;
	text-transform: uppercase;
	padding: 20px 35px;
	color: $white;
	background: $white;
	border-radius: 5px;
	overflow: hidden;
	position: relative;
	z-index: 1;

	@include breakpoint(small down) {
		font-size: 12px;
		padding: 10px 25px;
	}

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		@include goldGradient();
		@include transition(0.3s);
	}

	&:hover {
		color: $gold;
		
		&:before {
			opacity: 0;
		}
	}
}

@mixin goldBorderButton() {	
	display: inline-block;
	vertical-align: middle;
	font-size: 16px;
	font-weight: 700;
	line-height: 30px;
	text-transform: uppercase;
	padding: 19px 35px;
	color: $gold;
	border: 1px solid $gold;
	border-radius: 5px;
	position: relative;
	z-index: 1;

	@include breakpoint(small down) {
		font-size: 12px;
		padding: 9px 25px;
	}

	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: -1px;
		left: -1px;
		bottom: -1px;
		right: -1px;
		opacity: 0;
		border-radius: 5px;
		@include goldGradient();
		@include transition(0.3s);
	}

	&:hover {
		color: $white;
		border-color: transparent;
		
		&:before {
			opacity: 1;
		}
	}
}