@mixin homeTitle($color) {
	text-transform: uppercase;
	font-size: 30px;
	color: $color;
	text-align: center;
	line-height: 40px;

	strong,
	b {
		font-weight: 700;
	}

	@include breakpoint(small down) {
		font-size: 20px;
		line-height: 25px;
	}
}