.liste_ronds_luxe,
.liste_ronds_conciergerie {
	@include row();

	li {
		@include grid-column(3, 0);

		@include breakpoint(small down) {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 25px;
			}
		}

		figure {
			font-size: 0;
			margin-bottom: 30px;

			@include breakpoint(medium down) {
				margin-bottom: 15px;
			}

			img {
				width: 175px;
				height: auto;

				@include breakpoint(medium only) {
					width: 140px;
				}

				@include breakpoint(small down) {
					max-width: 100%;
				}
			}
		}

		h3 {
			@include homeTitle($gold);
			font-size: 19px;
			line-height: 30px;
			letter-spacing: -0.5px;

			strong {
				display: block;
			}

			@include breakpoint(large down) {
				font-size: 16px;
			}

			@include breakpoint(medium down) {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}

.liste_ronds_conciergerie li {
	width: calc(100% / 3);

	@include breakpoint(small down) {
		width: 100%;
	}
}

.liste_ronds {	
	@include row();

	li {
		@include grid-column(4, 0);

		@include breakpoint(small down) {
			width: 100%;

			&:not(:last-child) {
				margin-bottom: 25px;
			}
		}

		figure {
			font-size: 0;
			margin-bottom: 35px;

			@include breakpoint(medium down) {
				margin-bottom: 5px;
			}

			img {
				width: 191px;
				height: auto;

				@include breakpoint(small down) {
					max-width: 100%;
				}
			}
		}

		h3 {
			font-size: 16px;
			font-weight: 700;
			color: $blue;
			line-height: 30px;

			&.uppercase {
				text-transform: uppercase;
				margin-bottom: 10px;

				@include breakpoint(small down) {
					margin-bottom: 4px;
				}
			}

			@include breakpoint(medium down) {
				font-size: 14px;
				line-height: 20px;
			}
		}

		p {
			font-size: 14px;
			line-height: 24px;
			color: $blue;

			@include breakpoint(small down) {
				font-size: 12px;
				line-height: 20px;
			}
		}
	}
}