.liste_biens {
	@include row();
	margin: 0 -6px;

	li {
		@include grid-column(6, 12px);
		margin-bottom: 12px;

		&:last-child:not(:first-child) {
			float: left;
		}

		article {

			figure {
				font-size : 0;
				position  : relative;
				overflow  : hidden;
				min-height: 200px;

				img {
					min-width : 100%;
					min-height: 200px;
					height    : auto;
					width     : auto;
				}

				.top_left {
					display    : flex;
					align-items: center;
					position   : absolute;
					top        : 20px;
					left       : 20px;
				}

				.reference {

					font-size   : 14px;
					font-weight : 700;
					color       : $white;
					text-shadow : 1px 1px $greyTextShadow;
					margin-right: 6px;
				}

				.nouveaute,
				.exclu {
					text-transform: uppercase;
					height        : 20px;
					line-height   : 20px;
					padding-left  : 5px;
					padding-right : 5px;
					border-radius : 3px;
					font-size     : 11px;
					font-weight   : bold;
				}

				.nouveaute {
					margin-right: 5px;
					background  : #012955;
					color       : #fff;
				}

				.exclu {
					background: #fff;
					color     : #a88a57;
				}

				.flag-wrapper {
					position     : absolute;
					top          : 20px;
					right        : 20px;
					height       : 38px;
					line-height  : 38px;
					border-radius: 5px;
					font-size    : 14px;
					overflow     : hidden;
					background   : $blue;
					width        : 217px;
					padding-left : 38px;
					white-space  : nowrap;
					@include transition(0.3s);

					>a {
						width       : 160px;
						padding-left: 4px;
						color       : $white;
					}

					&:before {
						content    : "+";
						position   : absolute;
						top        : 0;
						left       : 8px;
						bottom     : 0;
						line-height: 38px;
						color      : $white;
						font-size  : 36px;
						font-weight: 700;
					}

					&:not(:hover) {
						width     : 38px;
						background: rgba(1, 41, 85, 0.4);
					}
				}
			}

			.details {
				padding    : 20px;
				padding-top: 15px;
				background : $white;
				//height: 115px;
				height     : 143px;
				overflow   : hidden;
				@include clearfix();
				@include transition(0.3s);

				@include breakpoint(medium up) {

					.left {
						float: left;
						width: calc(100% - 150px);
					}

					.right {
						float: right;
						width: 150px;
					}
				}

				@include breakpoint(small down) {
					height: auto;
				}

				.right {
					text-align: right;
				}

				.taille {
					color        : $blue;
					font-size    : 14px;
					font-weight  : 700;
					line-height  : 26px;
					margin-bottom: 3px;
					@include transition(0.3s);
					white-space: nowrap;
				}

				h3 {
					font-size    : 14px;
					color        : $otherGreyText;
					//line-height: 16px;
					//height: 32px;
					line-height  : 20px;
					height       : 60px;
				}

				.prix {
					color      : $blue;
					font-size  : 20px;
					font-weight: 700;
					line-height: 20px;
					@include transition(0.3s);

					span {
						font-size  : 12px;
						font-weight: 400;
					}
				}

				.date {
					color      : $gold;
					font-size  : 11px;
					line-height: 24px;
					height     : 30px;
					text-align : left;
				}

				.right .date {
					text-align: right;
				}

				.voir_plus {
					@include goldButton();
					font-size: 12px;
					padding  : 10px 18px;

					@include breakpoint(1200px up) {
						margin-bottom: 40px;
					}
				}
			}

			&:hover,
			&.mouseover {

				.details {
					background: $blue;

					.taille,
					.prix {
						color: $white;
					}
				}
			}
		}
	}

	&.accueil li {
		width: calc(100% / 3);

		@include breakpoint(large down) {
			width: 50%;
		}

		@include breakpoint(medium down) {
			width: 100%;
		}
	}
}

#recherche_right .liste_biens li {

	@include breakpoint(large down) {

		article {

			.details {
				//height: 210px;
				height: 235px;

				.left,
				.right {
					float: none;
					width: 100%;
				}

				.right {
					padding-top: 10px;
				}
			}
		}
	}

	@include breakpoint(small down) {
		width: 100%;
	}
}